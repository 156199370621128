import domReady from '@wordpress/dom-ready';

domReady(() => {
  const stickyLimit = 80;
  let sticky = 80;
  let timer = null;

  function stickyNavbar() {
    const st = window.scrollY;
    if (st >= sticky) {
      document.body.classList.add('sticky-navbar');
    } else {
      document.body.classList.remove('sticky-navbar');
    }

    if (st <= stickyLimit) {
      document.body.classList.add('transparent-navbar');
    } else {
      document.body.classList.remove('transparent-navbar');
    }
    sticky = st + 40;
  }

  window.addEventListener('scroll', () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      // actual callback
      stickyNavbar();
    }, 50);
  });

  stickyNavbar();
});
